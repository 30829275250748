import {useEffect} from "react";
import dayjs from "dayjs";
import {json} from "react-router-dom";

const updateTitle = (title) => {
    document.title = title.replace(/\//ig, '-');
};

export function useTitleFromComponent(title) {
    useEffect(() => {
        updateTitle(title);
    }, [title]);
}

export default function RequiredAsterisk() {
    return <i className="fa-solid fa-asterisk" style={{color: 'red'}}/>
}

export function setToken(token) {
    localStorage.setItem('ep-token', token);
    localStorage.setItem('ep-last-interaction', dayjs().toString());
    console.log(localStorage.getItem('ep-token'));
    console.log(localStorage.getItem('ep-last-interaction'));
}

export function getToken() {
    console.log(localStorage);
    // if (localStorage.getItem('ep-last-interaction') &&
    //     dayjs(localStorage.getItem('ep-last-interaction')).add(30, 'minutes').isBefore(dayjs())) {
    //     localStorage.setItem('ep-last-interaction', dayjs().toString());
    return localStorage.getItem('ep-token');
    // } else {
    //     localStorage.removeItem('ep-token');
    //     localStorage.removeItem('ep-last-interaction');
    //     return null;
    // }
}

export function logout() {
    localStorage.removeItem('ep-token');
    localStorage.removeItem('ep-last-interaction');
}