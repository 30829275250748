import logo from './logo.svg';
// import './App.css';
import EmailForm from "./components/emailForm";
import {Col, Row} from "reactstrap";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Home from "./pages/Home";
import Services from "./pages/Services";
import Company from "./pages/Company";
import Partners from "./pages/Partners";
import Contact from "./pages/Contact";
import NoPage from "./pages/NoPage";
import React, {useState} from "react";
import Layout from "./pages/Layout";
import AdminLayout from "./pages/AdminLayout";
import UploadPhoto from "./pages/PhotoUpload";
import PageEditor from "./pages/PagesEditor";
import "./font-awesome/css/all.css";
import Login from "./pages/Login";
import {getToken} from "./utils/hooks";

function App() {

    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Layout/>}>
                    <Route index element={<Home/>}/>
                    <Route path="services" element={<Services/>}/>
                    <Route path="company" element={<Company/>}/>
                    <Route path="partners" element={<Partners/>}/>
                    <Route path="contact" element={<Contact/>}/>

                    <Route path="*" element={<NoPage/>}/>
                </Route>

                <Route path="/admin" element={<AdminLayout/>}>
                    <Route path="/admin/login" element={<Login/>}/>
                    <Route path="/admin/pageeditor" element={<PageEditor/>}/>
                    <Route path="/admin/uploadimages" element={<UploadPhoto/>}/>
                </Route>

            </Routes>
        </BrowserRouter>

    );
}

export default App;
