import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import './Login.css';
import {Dayjs} from "dayjs";
import dayjs from "dayjs";
import {Alert, Button, Card, Col, Form, Input, Row} from "reactstrap";
import {useNavigate, useSearchParams} from "react-router-dom";
import {logout, setToken} from "../utils/hooks";


export default function Login() {
    const [email, setEmail] = useState();
    const [emailSent, setEmailSent] = useState(false);
    const [unauthEmail, setLoadUnauthEmail] = useState(false);
    const [loginError, setLoadLoginError] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();


    useEffect(() => {
            const fetchData = async () => {
                if (searchParams.get('token') && searchParams.get('token') !== '') {
                    const formData = new FormData();
                    formData.append('token', searchParams.get('token'));
                    fetch('/api/verify', {
                        method: 'POST',
                        headers: {
                            "Accept": "multipart/form-data"
                        },
                        body: formData
                    })


                        .then(response => {
                                if (response.status === 200) {
                                    response.json().then(user => {
                                        setToken(user.user.token);
                                        navigate('/');
                                    })
                                } else if (response.status === 401) {
                                    setLoadUnauthEmail(true);
                                } else {
                                    setLoadLoginError(true);
                                }
                            }
                        )
                        .catch(error => {
                            if (error.status === 401) {
                                setLoadUnauthEmail(true);
                            } else {
                                setLoadLoginError(true);
                            }
                        });

                } else if (searchParams.get('token') === '') {
                    logout();
                    navigate('/');
                }

            }
            fetchData().then(r => console.log(r));
        }
        ,
        [searchParams.get('token')]
    )
    const handleSubmit = e => {
        e.preventDefault();
        const formData = new FormData();
        formData.append('email', email);
        return fetch('/api/login', {
            method: 'POST',
            headers: {
                "Accept": "multipart/form-data"
            },
            body: formData
        }).then(res => {
                if (res.status === 200) {
                    setEmailSent(true);
                    setLoadUnauthEmail(false);
                    setLoadLoginError(false);
                } else if (res.status === 401) {
                    setEmailSent(false);
                    setLoadUnauthEmail(true);
                    setLoadLoginError(false);
                } else {
                    setEmailSent(false);
                    setLoadUnauthEmail(false);
                    setLoadLoginError(true);
                }
            }
        ).catch(err => {
            console.log(err);
            setEmailSent(false);
            setLoadUnauthEmail(false);
            setLoadLoginError(true);
        });
    }

    const validateEmail = (email) => {
        // console.log(String(email)
        //     .toLowerCase()
        //     .match(
        //         /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        //     ))
        const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return emailPattern.test(email);
    };


    return (
        <Card className={'report-card p-sm-3'}>

            <Row>
                <Col lg={4}></Col>
                <Col lg={4}>


                    <h5>Please Submit your email. You will get an email with access information.</h5>
                </Col>
                <Col lg={4}></Col>
            </Row>
            <Row>
                <Col lg={4}></Col>

                <Col lg={4}>
                    <Form onSubmit={handleSubmit}>
                        <span>
                            <Input id="email"
                                   name="emaul"
                                   placeholder="Your Email" type="text" onChange={e => setEmail(e.target.value)}/>


                            <Button color={"primary"} disabled={!email} type="submit"
                                    style={{marginTop: "3px"}}><i
                                className="fa fa-sign-in"
                                aria-hidden="true"></i> Submit</Button>
                        </span>
                        <div>
                            {
                                emailSent && <Alert color={"primary"} style={{margin: 24}}><i
                                    className="fa-sharp fa-solid fa-beat fa-xl fa-envelope"></i> &nbsp; &nbsp; Please
                                    check your
                                    email
                                    for a link.</Alert>
                            }
                            {
                                unauthEmail &&
                                <Alert color={"danger"} style={{margin: 24}}><i
                                    className="fa-sharp fa-solid fa-beat fa-xl fa-ban"></i> &nbsp; &nbsp; Email is
                                    not
                                    valid.</Alert>

                            }
                            {
                                loginError &&

                                <Alert color={"danger"} style={{margin: 24}}><i
                                    className="fa-sharp fa-solid fa-beat fa-xl fa-exclamation-trianglen"></i> &nbsp; &nbsp; Email
                                    is not valid.</Alert>

                            }
                        </div>

                    </Form>
                </Col>
                <Col lg={4}></Col>
            </Row>
        </Card>


    )
}
