import {useEffect, useState} from "react";
import {Button, Col} from "reactstrap";
import {confirmAlert} from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css';
import useToken from "../utils/useToken";
import Login from "./Login"; // Import css

export default function UploadPhoto() {

    const [image, setImage] = useState(null);
    const [uploadStatus, setUploadStatus] = useState('');
    const [images, setImages] = useState([]);
    const imageHandler = (e) => {
        const file = e.target.files[0];
        setImage(file);
    }

    function uploadImage() {
        const formData = new FormData();
        formData.append('image', image);
        fetch('/api/upload_image', {
            method: 'POST',
            body: formData,
            headers: {
                "Accept": "multipart/form-data"
            },
            credentials: "include"
        }).then(r => console.log(r.json()))
            .then(r => {
                // setUploadStatus(r);
                fetchImages();
            })
            .catch(error => {
                console.error(error);
            })
    }

    const fetchImages = () => {
        fetch('/api/get_images', {
            method: 'GET',
            "Content-Type": "application/json",

        })
            .then(response => response.json())
            .then(data => {
                // Do something with the image data
                setImages(data);
                console.log(data);
            })
            .catch(error => console.error(error));
    }

    useEffect(() => {
        fetchImages();
    }, []);

    const removeImage = async (id) => {
        confirmAlert({
            title: 'Confirm to delete',
            message: 'Are you sure you want delete this image?',
            buttons: [
                {

                    label: 'Yes',
                    onClick: () => {
                        const formData = new FormData();
                        formData.append('image_id', id);
                        fetch('/api/delete_image', {
                            method: 'DELETE',
                            body: formData,
                            headers: {
                                "Accept": "multipart/form-data"
                            },
                            credentials: "include"
                        }).then(r => console.log(r.json()))
                            .then(r => {
                                // setUploadStatus(r);
                                fetchImages();
                            })
                            .catch(error => {
                                console.error(error);
                            })
                    }
                },
                {
                    label: 'No',
                    onClick: () => alert('Click No')
                }
            ]
        });

    }

    return (<>
            <div style={{marginTop: "100px", marginBottom: "100px"}}>
                <input type="file" name="image" accept="image/*" multiple={false} onChange={imageHandler}/>
                <Button onClick={uploadImage} color={"primary"} disabled={!image}>Upload Image</Button>
            </div>
            <div>{uploadStatus}</div>
            <h3 style={{color: "red"}}>Click on image to delete</h3>
            {images.map(i => {
                return (

                    <img width={150} height={150} alt={"Delete Image"} className="" style={{cursor: "pointer"}}
                         src={"data:image/png;base64," + i.image} onClick={() => removeImage(i.image_id)}></img>

                );
            })}
        </>
    )
}