import React, {useEffect, useState} from "react";
import {Carousel} from "react-responsive-carousel";

const Services = () => {
    const [content, setContent] = useState('');

    const fetchData = async () => {
        const response = await fetch('/api/pages/services_2',
            {
                method: 'GET',
                cache: 'no-cache',
                credentials: 'same-origin',
                headers: {
                    'Content-Type': 'application/json'
                }
            });
        const html = await response.json();

        setContent(html.html);


    }
    useEffect(() => {
        fetchData().then(r => console.log(r));
    }, []);

    return (<>

            <div dangerouslySetInnerHTML={{__html: content}}/>
        </>
    )
}

export default Services;
