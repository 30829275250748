import {Outlet, Link} from "react-router-dom";
import "./Layout.css";
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import {Image} from "react-bootstrap";
import {
    Button,
    ButtonGroup,
    ButtonToolbar,
    Col,
    Collapse,
    NavbarBrand,
    NavbarToggler,
    NavItem,
    NavLink,
    Row
} from "reactstrap";
import EmailForm from "../components/emailForm";
import React, {useEffect, useState} from "react";
import {Carousel} from "react-responsive-carousel";
import TopNavBar from "./TopNavBar";
import useToken from "../utils/useToken";
import Login from "./Login";
import {getToken, useTitleFromComponent} from "../utils/hooks";


const AdminLayout = () => {
    useTitleFromComponent(`EventsPlanning.com / ${window.location.pathname.substring(1)}`);
    return (<Row>
            <Col lg={2} sm={0}>

            </Col>
            <Col lg={8} sm={12}>
                <Row style={{backgroundColor: "white", textAlign: "center"}}>
                    <Col lg={3} sm={4}>
                        <a href={'/'}><Image src="../images/img.png" width={290} height={80}/></a>
                    </Col>
                    {/*<ButtonToolbar aria-label="Navigation">*/}
                    <Col lg={9} sm={8}>

                        <TopNavBar/>

                    </Col>
                </Row>
                <Row style={{backgroundColor: "#0E487B"}}>
                    <hr/>
                </Row>
                <Row style={{backgroundColor: "white"}}>
                    <Col>
                        {getToken() ?
                            <Outlet/> : <Login/>
                        }
                    </Col>
                </Row>


                <Row style={{backgroundColor: "white", textAlign: "center"}}>
                    <Col>
                        <hr align="left" width="100%" size="2" noshade={"true"}/>

                        <p>
                            &copy; Copyright 2012 Production &amp; Event Services, Inc.
                            All Rights Reserved.
                        </p>
                        <hr align="left" width="100%" style={{height: "10px", color: "#333", backgroundColor: "#333"}}
                            noshade/>
                        <span>
                    <a href="/">|&nbsp;Main&nbsp;|</a>
                            &nbsp;<a href="/company">
                        |&nbsp;Company&nbsp;|
                    </a>
                            <a href="/services">
                        |&nbsp;Services&nbsp;|
                    </a> <a href="/partners">
                        |&nbsp;Partners&nbsp;|
                    </a>  <a href="/contact">
                        |&nbsp;Contact Us&nbsp;|
                    </a><a href="/privacy_policy" target="_blank">
                        |&nbsp;Privacy Policy&nbsp;|
                    </a><a href="/affiliate_policy"
                           target="_blank">|&nbsp;Affiliates Agreement&nbsp;|</a>
                            &nbsp;<a href="/buyer_policy" target="_blank">
                        |&nbsp;Buyers Agreement&nbsp;|
                    </a>
                     </span>
                        <br/>
                        <br/>
                    </Col>
                </Row>
            </Col>
        </Row>

    )
};

export default AdminLayout;