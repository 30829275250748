import RequiredAsterisk, {useTitleFromComponent} from "../utils/hooks";
import React, {useEffect, useState} from "react";
import Select from 'react-select';
import 'bootstrap/dist/css/bootstrap.min.css';
import ReCAPTCHA from "react-google-recaptcha";

import {
    Button,
    Card,
    Col,
    Input,
    Row,
    Form, Alert
} from "reactstrap";

export default function EmailForm() {
    const SITE_KEY = "6LftGF0qAAAAAN4MWu2En5uUHITIa0Wvo5MKXxK_";
    const SECRET_KEY = "6LftGF0qAAAAAB5ye_M9oEMvQDdSeUGpRGhcng_e";
    const [emailSent, setEmailSent] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isUserVerified, setIsUserVerified] = useState(false);
    const recaptchaRef = React.createRef();
    const [emailMessage, setEmailMessage] = useState({
        // "name": "Ronaldo Marques",
        // "email": "ronaldomarquesjr@gmail.com",
        // "phone_number": "88888888888",
        // "budget": "1000000",
        // "company_name": "TEST",
        // "start": "3-6 Months",
        // "description": "Description"
    });
    const onInputChange = (e => {
        let email = emailMessage;
        email[e.target ? e.target.name : 'start'] = e.target ? e.target.value : e.label;
        setEmailMessage(email);
    })
    const onSubmit = () => {
        const recaptchaValue = recaptchaRef.current.getValue();
        this.props.onSubmit(recaptchaValue);
    }

    function sendEmail() {
        setIsLoading(true);
        fetch('/api/send_email', {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(emailMessage)
        }).then(r => {
            setEmailSent(true);
            setIsLoading(false);
        })
    }

    const recaptaOnChange = (value) => {
        if (value) {
            setIsUserVerified(true);
        }
    }
    return (
        <Card className={'report-card p-sm-3'}>
            <Form>
                <Col>
                    <Row>
                        <span style={{"color": "#b22222"}}><strong>Contact us today!</strong></span>
                        <div><span style={{"color": "#000000"}}><strong>Phone:</strong></span> <span
                            style={{"color": "#000080"}}><strong>281-585-0569</strong></span></div>
                        <div><span style={{"color": "#000000"}}><strong>Email:</strong></span> <span
                            style={{"color": "#000080"}}><strong>info@eventsplanning.com</strong></span></div>
                        <Button
                            onClick={() => window.open('https://visitor.r20.constantcontact.com/d.jsp?llr=95ofyzwab&p=oi&m=1123998153690&sit=5dmrajnkb&f=3bc88f71-c2e6-4f3b-a60a-50c997cc9acd')}
                            color='secondary'>
                            Sign Up Now
                        </Button>
                    </Row>
                    <Row>
                        <Input
                            id="name"
                            name="name"
                            placeholder="Your Name"
                            type="text"
                            required={true}
                            onChange={onInputChange}/>
                    </Row>
                    <Row>
                        <Input
                            id="email"
                            name="email"
                            placeholder="Your Email"
                            type="text"
                            required={true}
                            onChange={onInputChange}/>
                    </Row>
                    <Row>
                        <Input
                            id="phone_number"
                            name="phone_number"
                            placeholder="Phone Number"
                            type="text"
                            required={true}
                            onChange={onInputChange}/>
                    </Row>
                    <Row>
                        <Input
                            id="budget"
                            name="budget"
                            placeholder="Your Budget ($)"
                            type="text"
                            required={true}
                            onChange={onInputChange}/>
                    </Row>
                    <Row>
                        <Input
                            id="company_name"
                            name="company_name"
                            placeholder="Company Name"
                            type="text"
                            onChange={onInputChange}
                        required={true}
                        />
                    </Row>
                    <Row>
                        <Select
                            className="basic-single neutral90 mb-2 me-2"
                            classNamePrefix="Select a Start Period"
                            // value={startValue}
                            // defaultValue={defaultValue}
                            // isDisabled={isDisabled}
                            // isLoading={isLoading}
                            id="start"
                            name={"start"}
                            onChange={onInputChange}
                            isSearchable={true}
                            options={[{value: 'now', label: 'Now'}, {
                                value: 'month',
                                label: 'Within a Month'
                            }, {value: '3months', label: '1-3 Months'},
                                {value: '6months', label: '3-6 Months'}, {
                                    value: 'after6months',
                                    label: 'After 6 Months'
                                }]}
                            // styles={customStyles}
                            // isMulti={isMulti}
                            placeholder={'Select a Start Period'}
                            required={true}
                        />
                    </Row>
                    <Row>
                        <Input
                            id="description"
                            name="description"
                            placeholder="Brief Description"
                            type="textarea"
                            onChange={onInputChange}
                            required={true}/>
                    </Row>
                    <Row>
                        <ReCAPTCHA sitekey={SITE_KEY} onChange={recaptaOnChange} size={"compact"} ref={recaptchaRef}/>
                        <Button color='primary' onClick={sendEmail}
                                disabled={!(!isLoading && isUserVerified)}>Send
                            Email</Button>

                        {
                            emailSent && <Alert color={"primary"} style={{margin: 0}}><i
                                className="fa-sharp fa-solid fa-beat fa-xl fa-envelope"></i>Thanks for
                                contacting
                                us.</Alert>
                        }
                    </Row>


                </Col>
            </Form>
        </Card>
    )
}