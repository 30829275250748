import {Editor} from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import React, {useEffect, useState} from "react";
import EditorState from "draft-js/lib/EditorState";
import {
    Button,
    Col,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Input,
    Row,
    UncontrolledDropdown
} from "reactstrap";

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import 'react-quill/dist/quill.bubble.css';


const PageEditor = () => {
    const [pages, setPages] = useState([]);
    const [selPage, setSelPage] = useState({})
    const [html, setHtml] = useState('');
    const [isSaved, setIsSaved] = useState(true);
    const [message, setMessage] = useState(null);
    const [seeHtml, setSeeHtml] = useState(false);


    const fetchPages = () => {
        fetch('/api/pages', {
            method: 'GET',
            "Content-Type": "application/json",

        })
            .then(response => response.json())
            .then(data => {
                setPages(data.data);
                setSelPage(data.data[0]);
                setHtml(data.data[0]['page_html'])
            })
            .catch(error => console.error(error));
    }

    useEffect(() => {
        fetchPages();
    }, []);

    const updateHtml = (html) => {
        console.log(html);
        setHtml(html);
        setIsSaved(false);
    }
    const revertPage = () => {
        setHtml(selPage['page_html']);
        setIsSaved(true);
    }

    const savePage = () => {

        const formData = new FormData();
        formData.append('page_id', selPage['page_id']);
        formData.append('page_html', html);
        fetch('/api/save_pages', {
            method: 'POST',
            body: formData,
            headers: {
                "Accept": "multipart/form-data"
            },
            credentials: "include"
        }).then(r => console.log(r.json()))
            .then(r => {

                setMessage('Page Saved Successfully!')
                setTimeout(() => {
                    setMessage(null);
                }, 5000);

            })
            .catch(error => {
                setMessage('Error has occurred!.');
                console.error(error);
            })

    }

    const seeHtmlToggle = () => {
        setSeeHtml(!seeHtml);
    }


    const toolbarOptions = [
        ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
        ['blockquote', 'code-block'],
        ['link', 'image', 'video', 'formula'],

        [{'header': 1}, {'header': 2}],               // custom button values
        [{'list': 'ordered'}, {'list': 'bullet'}, {'list': 'check'}],
        [{'script': 'sub'}, {'script': 'super'}],      // superscript/subscript
        [{'indent': '-1'}, {'indent': '+1'}],          // outdent/indent
        [{'direction': 'rtl'}],                         // text direction

        [{'size': ['small', false, 'large', 'huge']}],  // custom dropdown
        [{'header': [1, 2, 3, 4, 5, 6, false]}],

        [{'color': []}, {'background': []}],          // dropdown with defaults from theme
        [{'font': []}],
        [{'align': []}],

        ['clean']                                         // remove formatting button
    ];
    return (

        <>
            <Row style={{paddingTop: "20px"}}>
                <Col lg={4}>
                    <UncontrolledDropdown group>
                        <Button color="primary">
                            {selPage['page_name']}
                        </Button>
                        <DropdownToggle
                            caret
                            color="primary"
                        />
                        <DropdownMenu>

                            {
                                pages?.map(p => {
                                    return <DropdownItem
                                        onClick={(e) => {
                                            e.preventDefault();
                                            pages?.forEach(d => {
                                                if (p['page_id'] === d['page_id']) {
                                                    setSelPage(p);
                                                    setHtml(p['page_html']);
                                                }
                                            })

                                        }}
                                    >
                                        {p['page_name']}
                                    </DropdownItem>
                                })
                            }

                        </DropdownMenu>
                    </UncontrolledDropdown>
                </Col>
                <Col lg={4}>
                    {message && <h3>{message}</h3>}
                </Col>
                <Col lg={4} style={{textAlign: "right"}}>
                    <Button color={isSaved ? "secondary" : "success"} onClick={savePage}
                            disabled={isSaved}
                            style={{display: "inline-block", marginRight: "5px"}}>
                        Save
                    </Button>

                    <Button color={isSaved ? "secondary" : "danger"} onClick={revertPage}
                            disabled={isSaved}
                            style={{display: "inline-block", marginRight: "5px"}}>
                        Revert
                    </Button>
                    <Button color={"info"} onClick={seeHtmlToggle}
                            style={{display: "inline-block"}}>
                        {seeHtml ? 'See Page' : 'See Html Code'}
                    </Button>
                </Col>
            </Row>
            <Row>
                <Col lg={12}>
                    {seeHtml ?
                        <Input type="textarea" name="text" id="htmlText" style={{minHeight: "500px"}} value={html}
                               onChange={(event) => updateHtml(event.target.value)}/> :
                        <ReactQuill theme="snow" value={html} onChange={updateHtml}
                                    modules={{toolbar: toolbarOptions}}/>}
                </Col>
            </Row>

        </>
    )
        ;
}

export default PageEditor;