import {Outlet, Link} from "react-router-dom";
import "./Layout.css";
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import {Image} from "react-bootstrap";
import {
    Button,
    ButtonGroup,
    ButtonToolbar,
    Col,
    Collapse,
    NavbarBrand,
    NavbarToggler,
    NavItem,
    NavLink,
    Row
} from "reactstrap";
import EmailForm from "../components/emailForm";
import React, {useEffect, useState} from "react";
import {Carousel} from "react-responsive-carousel";
import TopNavBar from "./TopNavBar";
import {useTitleFromComponent} from "../utils/hooks";


const Layout = () => {
    useTitleFromComponent(`EventsPlanning.com / ${window.location.pathname.substring(1)}`);
    const [content, setContent] = useState('');
    const [content2, setContent2] = useState('');
    const [images, setImages] = useState([]);
    const fetchData = async () => {
        let path = window.location.pathname.substring(1);
        let page_name = path === '' ? 'main' : path;
        const response = await fetch(`/api/pages/${page_name}_1`,
            {
                method: 'GET',
                cache: 'no-cache',
                credentials: 'same-origin',
                headers: {
                    'Content-Type': 'application/json'
                }
            });
        const html = await response.json();

        setContent(html.html);
    }

    useEffect(() => {
        fetch('/api/get_images', {
            method: 'GET',
            "Content-Type": "application/json",

        })
            .then(response => response.json())
            .then(data => {
                // Do something with the image data
                setImages(data);
            })
            .catch(error => console.error(error));

        fetchData().then(r => console.log(r));


    }, []);
    return (<Row>
            <Col lg={2} sm={0}>

            </Col>
            <Col lg={8} sm={12}>
                <Row style={{backgroundColor: "white", textAlign: "center"}}>
                    <Col lg={3} sm={4}>
                        <a href={'/'}><Image src="../images/img.png" width={290} height={80}/></a>
                    </Col>
                    {/*<ButtonToolbar aria-label="Navigation">*/}
                    <Col lg={9} sm={8}>

                        <TopNavBar/>
                        {/*</div>*/}

                    </Col>
                </Row>
                <Row style={{backgroundColor: "#0E487B"}}>
                    <hr/>
                </Row>
                <Row style={{backgroundColor: "white"}}>
                    <Col lg={2} sm={12}>
                        <Row>
                            <div dangerouslySetInnerHTML={{__html: content}}/>

                        </Row>
                        <Row><Col><EmailForm title={'Email Form'}/></Col></Row>
                    </Col>
                    <Col lg={8}>
                        <div style={{
                            textAlign: 'center',
                            padding: '8px 0px 0px 0px',
                            fontSize: '24px',
                            fontFamily: 'Cronos Pro',
                            color: '#9E3B2A'
                        }}>
                            <span style={{color: '#0E4A79'}}>“SPECIAL EVENTS</span> FOR ALL OCCASIONS”
                        </div>
                        <div>
                            {images.length > 0 &&
                                <Carousel autoPlay={true} infiniteLoop={true} showThumbs={false}>
                                    {
                                        images.map(i => <div>
                                            <img width={800} height={600} alt={i.image_name} className=""
                                                 src={"data:image/png;base64," + i.image} key={i.image_id}></img>
                                        </div>)
                                    }

                                </Carousel>}

                        </div>
                        <Outlet/>
                    </Col>
                    <Col lg={2}>

                    </Col>
                </Row>
                <Row style={{backgroundColor: "white", textAlign: "center"}}>
                    <Col>
                        <hr align="left" width="100%" size="2" noshade={"true"}/>

                        <p>
                            &copy; Copyright 2012 Production &amp; Event Services, Inc.
                            All Rights Reserved.
                        </p>
                        <hr align="left" width="100%" style={{height: "10px", color: "#333", backgroundColor: "#333"}}
                            noshade/>
                        <span>
                    <a href="/">|&nbsp;Main&nbsp;|</a>
                            &nbsp;<a href="/company">
                        |&nbsp;Company&nbsp;|
                    </a>
                            <a href="/services">
                        |&nbsp;Services&nbsp;|
                    </a> <a href="/partners">
                        |&nbsp;Partners&nbsp;|
                    </a>  <a href="/contact">
                        |&nbsp;Contact Us&nbsp;|
                    </a><a href="/privacy_policy" target="_blank">
                        |&nbsp;Privacy Policy&nbsp;|
                    </a><a href="/affiliate_policy"
                           target="_blank">|&nbsp;Affiliates Agreement&nbsp;|</a>
                            &nbsp;<a href="/buyer_policy" target="_blank">
                        |&nbsp;Buyers Agreement&nbsp;|
                    </a>
                     </span>
                        <br/>
                        <br/>
                    </Col>
                </Row>
            </Col>
        </Row>

    )
};

export default Layout;