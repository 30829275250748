import Nav from "react-bootstrap/Nav";
import {NavItem, NavLink} from "reactstrap";
import Navbar from "react-bootstrap/Navbar";
import React from "react";
import {getToken} from "../utils/hooks";

const TopNavBar = () => {
    const token = getToken();
    console.log(token);
    return (
        <Navbar color="faded" light style={{textAlign: "center"}}>
            <Nav navbar>
                <NavItem>
                    <NavLink href="/"><i className="fa-solid fa-home" aria-hidden="true"></i> Home
                        |</NavLink>
                </NavItem>
                <NavItem>
                    <NavLink href="/services"><i className="fa-solid fa-bell-concierge"
                                                 aria-hidden="true"></i> Services |</NavLink>
                </NavItem>
                <NavItem>
                    <NavLink href="/company"><i className="fa-solid fa-building"></i> Company
                        |</NavLink>
                </NavItem>
                <NavItem>
                    <NavLink href="/partners"><i className="fa-solid fa-handshake"></i> Partners
                        |</NavLink>
                </NavItem>
                <NavItem>
                    <NavLink href="/contact"><i
                        className="fa-solid fa-address-card"></i> Contact |</NavLink>
                </NavItem>
                {!token &&
                    <NavItem>
                        <NavLink href="/admin/login"><i className="fa-solid fa-lock-open"></i> Login</NavLink>
                    </NavItem>
                }
                {token &&
                    <NavItem>
                        <NavLink href="/admin/uploadimages"><i className="fa fa-upload" aria-hidden="true"></i> Upload
                            Images |</NavLink>
                    </NavItem>
                }
                {token &&
                    <NavItem>
                        <NavLink href="/admin/pageeditor"><i className="fa fa-code" aria-hidden="true"></i> Edit
                            Pages |</NavLink>
                    </NavItem>
                }
                {token &&
                    <NavItem>
                        <NavLink href="/admin/login?token="><i className="fa-solid fa-lock"></i> Logout</NavLink>
                    </NavItem>
                }
            </Nav>
        </Navbar>
    )
}
export default TopNavBar;